import React from 'react';
import * as S from './styles';

type TableRow = TranslatedNode[];

interface TableProps {
  headerRow: TableRow;
  rows: TableRow[];
}

/**
 * A basic static html table whose purpose is mostly styling
 *
 * Uses internal indices for mapping keys so please
 * refactor if filtering/sorting rows
 */
const Table = ({ headerRow, rows }: TableProps) => (
  <S.Table>
    <S.Head>
      <tr>
        {headerRow.map((cell, i) => (
          // eslint-disable-next-line react/no-array-index-key
          <th key={i}>{cell}</th>
        ))}
      </tr>
    </S.Head>
    <tbody>
      {rows.map((row, i) => (
        // eslint-disable-next-line react/no-array-index-key
        <tr key={i}>
          {row.map((cell, j) => (
            <td key={j}>{cell}</td> // eslint-disable-line react/no-array-index-key
          ))}
        </tr>
      ))}
    </tbody>
  </S.Table>
);

export default Table;

import React, { FC } from 'react';
import * as S from './styles';
import { BaseButtonProps } from './constants';
import CircleBackground from './serated-circle.svg';

const getBadgeDisplay = (n: number): string => (n > 99 ? '99+' : n.toString());

const Container: FC<BaseButtonProps> = ({
  children,
  className,
  error,
  vertical,
  badgeCount,
  lock,
}) => (
  <S.Container className={className} $vertical={!!vertical}>
    <>
      {children}
      {typeof badgeCount !== 'undefined' && badgeCount !== null && (
        <S.BadgeCount>
          <S.BadgeCenter>
            <span>{getBadgeDisplay(badgeCount)}</span>
          </S.BadgeCenter>
        </S.BadgeCount>
      )}
      {lock && (
        <>
          <S.LockIconShadow name={lock} />
          <S.LockIcon name={lock} />
        </>
      )}

      {lock === 'unlocked' && (
        <S.UnlockedBackground Svg={CircleBackground} data-testid="unlockedBackground" />
      )}

      <S.ErrorContainer>
        <S.Error data-testid="btn-error" $show={!!error}>
          {error && error}
        </S.Error>
      </S.ErrorContainer>
    </>
  </S.Container>
);

export default Container;

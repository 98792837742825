import styled, { css, keyframes } from 'styled-components';

const fade = keyframes`
  from {
    opacity: 1;
  }

  to {
    opacity: .5;
  }
`;

export const LoadingSkeleton = styled.div<{
  height?: 's' | 'm' | 'l' | 'xl';
  width?: 'min' | 's' | 'm' | 'l' | 'grow';
}>(({ theme, height = 'm', width = 'min' }) => {
  const heightMap = {
    s: theme.sz.s4,
    m: theme.sz.s8,
    l: theme.sz.s16,
    xl: theme.sz.s24,
  };

  const widthMap = {
    // Same as height so it looks like a circle
    min: heightMap[height],
    s: theme.sz.s32,
    m: theme.sz.s48,
    l: theme.sz.s64,
    grow: '100%',
  };

  return css`
    border-radius: ${theme.bo.round};
    background-color: ${theme.co.secondary70};
    height: ${heightMap[height]};
    min-width: ${heightMap[height]};
    max-width: ${widthMap[width]};
    width: 100%;
    animation: ${fade} 1s ease-in infinite alternate;
  `;
});

import styled, { css } from 'styled-components';

export const PillContainer = styled.div(
  ({ theme, color }) => css`
    background-color: ${color === 'green' ? theme.co.success80 : theme.co.neutral90};
    border-radius: ${theme.sz.s4} / 50%;
    color: ${color === 'green' ? theme.co.success0 : theme.co.neutral20};
    font-weight: ${theme.DEPRECATED_ty.bold};
    padding: ${theme.sz.s2} ${theme.sz.s4};
    text-align: center;
    text-transform: uppercase;
    display: flex;
    justify-content: center;
  `,
);

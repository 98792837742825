import React from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { AnimatePresence } from 'framer-motion';
import { Tooltip } from 'src/components';
import * as S from './styles';

const msgs = defineMessages({
  tooltipFlower: {
    id: 'learning_session_tooltip_flower',
  },
});

interface GrowthLevelIndicatorProps {
  growthLevel: number;
  isItemDueForReview: boolean;
  className?: string;
}

export const GrowthLevelIndicatorClassic = ({
  growthLevel,
  isItemDueForReview,
  className,
}: GrowthLevelIndicatorProps) => {
  const intl = useIntl();

  const getIcon = () => {
    if (growthLevel >= 7) {
      return isItemDueForReview ? <S.Review /> : <S.GL6 />;
    }

    switch (growthLevel) {
      case 0:
        return <S.GL0 />;
      case 1:
        return <S.GL1 />;
      case 2:
        return <S.GL2 />;
      case 3:
        return <S.GL3 />;
      case 4:
        return <S.GL4 />;
      case 5:
        return <S.GL5 />;
      case 6:
        return <S.GL6 />;
      case undefined:
        return <S.AlreadyKnown />;
    }
  };

  return (
    <Tooltip placement="auto" tooltip={intl.formatMessage(msgs.tooltipFlower)}>
      <S.Wrapper data-testid="growthLevelIndicator" className={className}>
        <AnimatePresence>
          <S.IconContainer>
            <S.MainIcon>{getIcon()}</S.MainIcon>
          </S.IconContainer>
        </AnimatePresence>
      </S.Wrapper>
    </Tooltip>
  );
};

import styled, { css } from 'styled-components';

export const ButtonContainer = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  width: ${({ theme }) => theme.sz.s16};
`;

export const ButtonLabel = styled.span(
  ({ theme }) => css`
    color: ${theme.co.neutral30};
    font-size: ${theme.DEPRECATED_ty.smaller};
    margin-top: ${theme.sz.s2};
    text-align: center;
    line-height: 1.2;
  `,
);

import React, { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { Tooltip } from 'src/components';
import * as S from './styles';

const msgs = defineMessages({
  tooltipPoints: {
    id: 'learning_session_tooltip_points',
  },
  pointsStreak: {
    id: 'learning_session_points_streak',
  },
});

type PointsPillProps = {
  points: string;
  streak?: number;
};

export const PointsPill: FC<PointsPillProps> = ({ points, streak }) => {
  const intl = useIntl();
  const streakOptions = [5, 10, 15];
  const animate = streak && streakOptions.includes(streak);

  return (
    <S.Container>
      <S.PointsContainer>
        <Tooltip placement="bottom" tooltip={intl.formatMessage(msgs.tooltipPoints)}>
          <S.Pill $animate={!!animate}>{points}</S.Pill>
        </Tooltip>

        {!!animate && (
          <>
            <S.PointsCelebration>
              <S.PointsCelebrationInner />
            </S.PointsCelebration>
            <S.CelebrationText>
              {intl.formatMessage(msgs.pointsStreak, {
                streakNumber: streak,
              })}
            </S.CelebrationText>
          </>
        )}
      </S.PointsContainer>
    </S.Container>
  );
};

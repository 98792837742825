import React from 'react';
import { Color } from 'src/utils/theme';
import { IconName } from 'src/components';

// Allow `any`s for awkward styled-components types
/* eslint @typescript-eslint/no-explicit-any: "off" */

export const ButtonColor = {
  BLACK: 'black',
  BLUE: 'blue',
  CORAL: 'coral',
  GREEN: 'green',
  GREY: 'grey',
  LIGHT_BLUE: 'light-blue',
  PURPLE: 'purple',
  RED: 'red',
  TURQUOISE: 'turquoise',
  WHITE: 'white',
  YELLOW: 'yellow',
} as const;
export type ButtonColor = (typeof ButtonColor)[keyof typeof ButtonColor];

export const ButtonType: { [key: string]: string } = {
  PRIMARY: 'primary',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
} as const;

export type ButtonType = (typeof ButtonType)[keyof typeof ButtonType];

/*
 * Define the allowed button sizes which will affect css properties like border-radius, font-size etc
 * Names commented here are those used by design and refer to pixel sizes. We are transitioning
 * towards making the buttons more reflect those seen on mobile, so these wont be the actual button sizes.
 */
export const ButtonSize = {
  /* Also called solid-32, outline-32 */
  EXTRA_SMALL: 'extraSmall',
  /* Also called solid-40, outline-40 */
  SMALL: 'small',
  /* Also called solid-56, outline-56 */
  MEDIUM: 'medium',
  /* Also called solid-64, outline-64 */
  LARGE: 'large',
};
export type ButtonSize = (typeof ButtonSize)[keyof typeof ButtonSize];

export type ButtonUse = 'scb' | 'ms' | 'default';

export interface BaseButtonProps {
  buttonColor?: ButtonColor;
  buttonType?: ButtonType;
  children?: React.ReactNode;
  className?: string;
  disabled?: boolean;
  vertical?: boolean;
  buttonSize?: ButtonSize;
  secondary?: boolean;
  buttonUse?: ButtonUse;

  /** If true, the button will not grow wider than its content. */
  fitContent?: boolean;

  /**
   * Show a numeric badge in the top right corner in a jaunt
   * trapezium.
   *
   * - null and undefined render no badge
   * - 0 renders 0,
   * - 1-99 renders the number
   * - over 99 renders '99+'
   *
   * Note: Only supported for round buttons
   */
  badgeCount?: number | null;

  /**
   * A numerical position which shows a small icon and enables
   * pressing the given number on the keyboard to click the button
   */
  positionLabel?: number;

  /**
   * The name of the icon to display
   *
   * Leave empty to not-render an icon
   */
  icon?: IconName;

  /**
   * The color of the icon to display
   *
   * Leave empty to default to theme fontColor
   */
  iconColor?: Color;

  /**
   * The content to show below the button as an error
   * message
   */
  error?: TranslatedNode;

  /**
   * Adds a pale highlight colour to the background of the
   * button.
   *
   * Only applicable for secondary buttons as primary buttons
   * already have a background
   */
  highlight?: boolean;

  /**
   * Whether to show a small locked or unlocked icon at the bottom right.
   *
   * Note: Only supported for round buttons.
   * Does not disable the button and onClick may still fire.
   */
  lock?: 'locked' | 'unlocked';
}

import styled from 'styled-components';

export const Grid = styled.div`
  display: flex;
  justify-content: space-between;
`;

export enum FlexBreakpointOptions {
  DESKTOP = 0,
  MOBILE = 1,
}

export interface FlexBreakpoints {
  desktop: string;
  mobile: string;
}

export function instanceOfFlexBreakpoints(flex: string | FlexBreakpoints): flex is FlexBreakpoints {
  return (flex as FlexBreakpoints).desktop !== undefined;
}

export interface ColProps {
  $flex?: string | FlexBreakpoints;
}

export function getColFlex(flex: string | FlexBreakpoints, breakpoint: FlexBreakpointOptions) {
  if (instanceOfFlexBreakpoints(flex)) {
    switch (breakpoint) {
      case FlexBreakpointOptions.DESKTOP: {
        return flex.desktop;
      }
      case FlexBreakpointOptions.MOBILE: {
        return flex.mobile;
      }
    }
  }

  return flex;
}

export const Col = styled.div<ColProps>`
  flex: ${props => (props.$flex ? getColFlex(props.$flex, FlexBreakpointOptions.MOBILE) : 'auto')};
  margin: 0 ${props => props.theme.sz.s3};
  &:first-child {
    margin-left: ${props => props.theme.sz.s12};
  }
  &:last-child {
    margin-right: ${props => props.theme.sz.s12};
  }
  @media (min-width: ${props => props.theme.sz.md}) {
    flex: ${props =>
      props.$flex ? getColFlex(props.$flex, FlexBreakpointOptions.DESKTOP) : 'auto'};
  }
`;

import React, { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { IconName } from 'src/components';
import {
  NextSession,
  SessionType,
  SuggestableSessionType,
  UnlockedState,
} from 'src/api/shared/types';
import { ClassicButton, ClassicLinkButton } from 'src/componentsClassic';
import { ButtonColor } from '../ClassicButton/constants';
import * as S from './styles';

const msgs = defineMessages({
  learn: {
    id: 'modeTitle_learn',
  },
  review: {
    id: 'modeTitle_review',
  },
  classic_review: {
    id: 'modeTitle_review',
  },
  difficult_words: {
    id: 'modeTitle_difficult',
  },
  speed_review: {
    id: 'modeTitle_speed',
  },
  video: {
    id: 'modeTitle_locals',
  },
  listening_skills: {
    id: 'modeTitle_listening',
  },
});

const sessionTypeToIcon: Record<SuggestableSessionType, IconName> = {
  learn: 'learn',
  review: 'review',
  classic_review: 'review',
  difficult_words: 'difficultWords',
  speed_review: 'speedReview',
  video: 'learnWithLocals',
  listening_skills: 'listening',
};

const sessionTypeToColor: Record<SuggestableSessionType, ButtonColor> = {
  learn: ButtonColor.TURQUOISE,
  review: ButtonColor.LIGHT_BLUE,
  classic_review: ButtonColor.LIGHT_BLUE,
  difficult_words: ButtonColor.LIGHT_BLUE,
  speed_review: ButtonColor.LIGHT_BLUE,
  video: ButtonColor.LIGHT_BLUE,
  listening_skills: ButtonColor.LIGHT_BLUE,
};

/**
 * The backend may return an empty ModeSelector object if the user hasn't learnt enough items fully
 */
const getSessionOptionsOrDefault = (
  maybeEmptySessionOptions: NonNullable<NextSession['modeSelector']> | Record<string, unknown>,
): NonNullable<NextSession['modeSelector']> => {
  const sessionOptionsIsEmpty =
    !maybeEmptySessionOptions || Object.keys(maybeEmptySessionOptions).length === 0;
  if (!sessionOptionsIsEmpty)
    return maybeEmptySessionOptions as NonNullable<NextSession['modeSelector']>;

  const unlockedDisabledMode = {
    url: '',
    isEnabled: false,
    unlockedState: 'AlwaysUnlocked' as UnlockedState,
  };
  return {
    [SessionType.LEARN]: unlockedDisabledMode,
    [SessionType.REVIEW]: unlockedDisabledMode,
    [SessionType.REVIEW_ALEX]: unlockedDisabledMode,
    [SessionType.SPEED_REVIEW]: unlockedDisabledMode,
    [SessionType.DIFFICULT_WORDS]: unlockedDisabledMode,
    [SessionType.AUDIO]: unlockedDisabledMode,
    [SessionType.VIDEO]: unlockedDisabledMode,
  };
};

export const ModeSelector: FC<{
  maybeEmptySessionOptions: NonNullable<NextSession['modeSelector']> | Record<string, unknown>;
  convertUrl?: (url: string) => string;
}> = ({ maybeEmptySessionOptions, convertUrl = url => url }) => {
  const intl = useIntl();
  const sessionOptions = getSessionOptionsOrDefault(maybeEmptySessionOptions);

  return (
    <>
      {Object.entries(sessionOptions).map(
        ([sessionType, { isEnabled, url, badgeCount, unlockedState }]) => {
          return (
            <S.ButtonContainer key={sessionType}>
              {unlockedState !== 'Locked' && (
                <>
                  {isEnabled ? (
                    <ClassicLinkButton
                      href={convertUrl(url)}
                      disabled={false}
                      icon={sessionTypeToIcon[sessionType as SuggestableSessionType]}
                      buttonColor={sessionTypeToColor[sessionType as SuggestableSessionType]}
                      badgeCount={badgeCount === 0 ? undefined : badgeCount}
                      aria-label={sessionType}
                      buttonUse="ms"
                      data-testid={`ms-unlocked-${sessionType}`}
                      lock={
                        unlockedState === 'TemporarilyUnlocked'
                          ? /* istanbul ignore next: untested branch of code, please test */ 'unlocked'
                          : undefined
                      }
                    />
                  ) : (
                    <ClassicButton
                      disabled
                      icon={sessionTypeToIcon[sessionType as SuggestableSessionType]}
                      buttonColor={sessionTypeToColor[sessionType as SuggestableSessionType]}
                      aria-label={sessionType}
                      buttonUse="ms"
                      data-testid={`ms-unlocked-disabled-${sessionType}`}
                      lock={
                        unlockedState === 'TemporarilyUnlocked'
                          ? /* istanbul ignore next: untested branch of code, please test */ 'unlocked'
                          : undefined
                      }
                    />
                  )}
                  <S.ButtonLabel>
                    {intl.formatMessage(msgs[sessionType as SuggestableSessionType])}
                  </S.ButtonLabel>
                </>
              )}
            </S.ButtonContainer>
          );
        },
      )}
    </>
  );
};

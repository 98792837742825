import { useRouter } from 'next/router';
import { useEffect, useState } from 'react';
import { Url } from 'next/dist/shared/lib/router/router';

const useSafePush = () => {
  const [onChanging, setOnChanging] = useState(false);
  const handleRouteChange = () => {
    setOnChanging(false);
  };
  const router = useRouter();
  // safePush is used to avoid route pushing errors
  // when users click multiple times or when the network is slow:
  // "Error: Abort fetching component for route"
  const safePush = async (path: string | Url) => {
    if (onChanging) {
      return;
    }
    setOnChanging(true);
    await router.push(path);
  };

  const safeReload = () => {
    /* istanbul ignore next: tested manually */
    if (onChanging) {
      return;
    }
    setOnChanging(true);
    router.reload();
  };

  useEffect(() => {
    router.events?.on('routeChangeComplete', handleRouteChange);

    return () => {
      router.events?.off('routeChangeComplete', handleRouteChange);
    };
  }, [router, setOnChanging]);
  return { safePush, safeReload };
};

export default useSafePush;

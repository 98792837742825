import React, { forwardRef } from 'react';
import { useKeyboardShortcut } from 'src/hooks';
import Container from './container';
import * as S from './styles';
import { BaseButtonProps, ButtonColor, ButtonSize, ButtonType } from './constants';

interface ButtonProps extends React.HTMLProps<HTMLButtonElement>, BaseButtonProps {
  type?: 'button' | 'submit' | 'reset';
  loading?: boolean;

  onClick?: (event?: React.MouseEvent<HTMLButtonElement>) => void | Promise<void> | null;

  /**
   * The content to show in place of children whilst the
   * button is loading
   */
  loadingContent?: TranslatedNode;
}

const ClassicButton = forwardRef(function InnerButton(
  {
    as,
    badgeCount,
    buttonColor = ButtonColor.YELLOW,
    buttonType = ButtonType.PRIMARY,
    children,
    className,
    disabled,
    error,
    icon,
    iconColor,
    buttonSize = ButtonSize.MEDIUM,
    loading,
    loadingContent,
    onClick,
    positionLabel,
    secondary,
    type = 'button',
    buttonUse = 'default',
    vertical = false,
    highlight = false,
    fitContent = false,
    lock,
    ...rest
  }: ButtonProps,
  ref,
) {
  useKeyboardShortcut(
    positionLabel ? positionLabel.toString() : '',
    onClick && !disabled ? () => onClick() : () => {},
  );

  const ariaLabel = rest['aria-label'];

  if (buttonUse === 'ms' && !ariaLabel) {
    throw new Error('You forgot to add an aria-label for a button with no text content');
  }

  // @ts-ignore
  return (
    <S.Variables
      styleProps={{
        $buttonSize: buttonSize,
        disabled: !!disabled,
        $loading: !!loading,
        $secondary: !!secondary,
        $highlight: !!highlight,
        $buttonColor: buttonColor,
        $buttonType: buttonType,
        $vertical: !!vertical,
        $buttonUse: buttonUse,
        $fitContent: fitContent,
      }}
    >
      <Container
        className={className}
        error={error}
        vertical={vertical}
        badgeCount={badgeCount}
        lock={lock}
      >
        <S.Button
          disabled={loading || !!disabled}
          onClick={onClick}
          $vertical={vertical}
          $secondary={!!secondary}
          type={type}
          ref={ref}
          as={/* eslint-disable-line @typescript-eslint/no-explicit-any */ as as any}
          {...rest}
        >
          {positionLabel && <S.PositionLabel>{positionLabel}</S.PositionLabel>}
          {icon && (
            <S.Icon
              $disabled={disabled}
              iconColor={iconColor}
              large={buttonUse === 'scb' || buttonUse === 'ms'}
              name={icon}
              $vertical={vertical}
              isCompact={children == null}
            />
          )}
          {loading && <S.ButtonLoader />}
          {loadingContent && loading ? (
            <span>{loadingContent}</span>
          ) : (
            <S.ButtonChildren $withPositionLabel={!!positionLabel}>{children}</S.ButtonChildren>
          )}
        </S.Button>
      </Container>
    </S.Variables>
  );
});

export default ClassicButton;

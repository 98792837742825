import { darken, mix } from 'polished';
import Icons from './Icon/icons';

const fade = (amount: number) => (color: string) => mix(amount, '#FFF', color);
export const disable = fade(0.3);
export const hover = fade(0.1);
export const active = (color: string) => darken(0.01, color);

export const iconOptions = Object.keys(Icons).reduce((acc, curr) => {
  return {
    ...acc,
    [curr]: curr,
  };
}, {});

import styled, { css } from 'styled-components';

export const AdContainer = styled.div<{ $vertical: boolean }>(
  ({ theme, $vertical }) => css`
    width: 100%;
    text-align: center;
    padding: ${$vertical ? theme.sz.s12 : theme.sz.s4} ${theme.sz.s4}
      ${$vertical ? theme.sz.s12 : theme.sz.s4} ${theme.sz.s4};
  `,
);

export const Ad = styled.ins(
  () => css`
    display: block;
    margin: auto;
  `,
);

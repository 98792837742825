import React from 'react';
import { ThemeProvider } from 'styled-components';
import { Theme } from 'src/utils/theme';

/**
 * A function to translate style-dependent props and parent
 * theme into a more specific child theme
 */
type ThemeMapper<
  StyleProps extends Record<string, unknown>,
  StyleVariables extends Record<string, unknown>,
> = (styleProps: StyleProps) => (parentTheme: Theme) => Theme & StyleVariables;

/**
 * Props for the Variables wrapping component
 */
interface VariablesProps<StyleProps> {
  children: React.ReactNode;
  styleProps: StyleProps;
}

/**
 * @example
 *
 * // ComponentName/ComponentNameStyles.ts
 * import styled, {
 *   DefaultTheme,
 *   ThemedStyledInterface,
 * } from 'styled-components';
 * import createVariablesWrapper from '../createVariabelsWrapper';
 *
 * // any props which affect the style
 * type StyleProps = {
 *   hasError: boolean;
 * }
 *
 * // properties to be added onto theme object
 * type StyleVariables = {
 *   background: string;
 * }
 *
 * // overwrite the theme used by styled locally
 * interface InputTheme extends DefaultTheme, StyleVariables {}
 * const styled = (defaultStyled as ThemedStyledInterface<InputTheme>);
 *
 * export const Variables = createVariablesWrapper<StyleProps, StyleVariables>(
 *   ({ hasError }) => theme => ({
 *     // Vary the background depending on whether there is an error
 *     background: hasError ? theme.co.error60 : theme.co.primary99,
 *     // Passing static variables is also encouraged
 *     borderColor: theme.co.information40,
 *     // Pass the rest of the theme through so you can still access atomic properties
 *     ...theme
 *   })
 * );
 *
 * export const SomeStyledComponent = styled.div`
 *   // You can use the variables you define here, all typed!
 *   background: ${props => props.theme.background};
 *   border: 2px solid ${props => props.theme.borderColor};
 * `
 *
 * // ComponentName/index.tsx
 *
 * import * as Styles from ''
 *
 * // in your component's return:
 * return (
 *   <S.Variables styleProps={{ hasError: true }}>
 *      // Nesterd components have access to all the theme properties
 *      <S.SomeStyledComponent />
 *      // ...
 *   </S.Variables>
 * )
 *
 * @example
 *
 * // No dynamic property changing is also fine if you just want to
 * // component-level variables
 * export const Variables = createVariablesWrapper<{}, StyleVariables>(
 *   () => theme => ({
 *     background: theme.co.information80,
 *     ...theme
 *   })
 * );
 */
export default function createVariablesWrapper<
  StyleProps extends Record<string, unknown>,
  StyleVariables extends Record<string, unknown>,
>(themeMapper: ThemeMapper<StyleProps, StyleVariables>) {
  return function Variables({ children, styleProps }: VariablesProps<StyleProps>) {
    return (
      <ThemeProvider theme={themeMapper({ ...styleProps })}>
        <>{children}</>
      </ThemeProvider>
    );
  };
}

import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';

export const LabelButton = styled.label<{ $checked: boolean }>(
  ({ theme, $checked }) => css`
    align-items: center;
    background: ${$checked ? theme.co.tertiary70 : 'transparent'};
    border-radius: ${theme.bo.medium};
    border: 2px solid ${$checked ? theme.co.tertiary30 : theme.co.secondary90};
    box-shadow: 0 4px 0 ${$checked ? theme.co.tertiary30 : theme.co.secondary90};
    display: flex;
    justify-content: space-between;
    padding: ${theme.sz.s4} ${theme.sz.s6};
    transition:
      background 0.2s,
      border 0.2s,
      box-shadow 0.2s;

    &:before {
      content: ' ';
      display: inline-block;
      position: relative;
      width: ${theme.sz.s4};
      height: ${theme.sz.s4};
      border-radius: 50%;
      border: 2px solid ${$checked ? theme.co.tertiary30 : theme.co.secondary90};
      background-color: ${$checked ? theme.co.tertiary50 : 'transparent'};
      transition:
        border 0.2s,
        background-color 0.2s;
    }
  `,
);

export const MainLabel = styled.span(
  ({ theme }) => css`
    font-weight: ${theme.DEPRECATED_ty.semiBold};
    margin-left: ${theme.sz.s4};
    color: ${theme.co.secondary20};
    margin-right: ${theme.sz.s2};
  `,
);

export const SideLabel = styled.span<{ $checked: boolean }>(
  ({ theme, $checked }) => css`
    color: ${$checked ? theme.co.tertiary30 : theme.co.tertiary50};
    transition: color 0.2s;
    margin-left: auto;
  `,
);

export const RadioInput = styled.input(
  () => css`
    display: none;
  `,
);

const BaseTick = ({ className }: { className?: string }) => (
  <motion.svg
    viewBox="0 0 18 13"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    initial={{
      scale: 0.1,
      opacity: 0.1,
    }}
    animate={{
      scale: 1,
      opacity: 1,
    }}
    exit={{
      scale: 0.1,
      opacity: 0.1,
    }}
    className={className}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M6.0517 8.06391L8.21705 6.43992C9.37186 5.49821 13.2217 1.82824 14.9345 0.426103C14.9365 0.424373 14.9386 0.422836 14.9407 0.421107C15.0657 0.324447 15.2459 0.346931 15.3431 0.471262L17.1758 2.8152C17.2714 2.93751 17.2513 3.11334 17.1305 3.21106L13.999 5.7462C13.3068 6.20039 12.1099 7.49664 10.8836 8.64041C9.48357 9.94617 8.04822 11.1183 7.33185 11.745C7.02812 12.0107 6.94125 12.1653 6.52764 12.4815C6.25503 12.6899 5.99604 12.6716 5.75725 12.5412C5.72468 12.5249 5.69144 12.5061 5.65742 12.4849C5.32876 12.2781 0.104948 6.68926 0.104948 6.68926C-0.00647453 6.59855 -0.0327598 6.43973 0.0433902 6.31818L2.11017 4.33033C2.19395 4.19668 2.3707 4.15584 2.50503 4.23905C2.51179 4.24328 2.51836 4.24779 2.52474 4.2525C2.52474 4.2525 4.10475 6.1902 4.60215 6.62267L6.0517 8.06391Z"
      fill="#261A52"
    />
  </motion.svg>
);

export const Tick = styled(BaseTick)(
  ({ theme }) => css`
    width: ${theme.sz.s3};
    height: ${theme.sz.s3};
    position: absolute;
    margin-left: 0.25rem; /* optical alignment, sorry */

    & > * {
      fill: ${theme.co.tertiary0};
    }
  `,
);

/*
 * This is the entry point for Classic shared visual components
 *
 * One-off components should go directly in the app of
 * the folder they are used by.
 *
 * Utility components, those which deal with bits of functionality
 * specific to this app and could not be exported to any environment,
 * should go in src/utilityComponents
 *
 * ---
 *
 * Components should be exported from here and imported in the `/apps` section of `web_client` like so:
 *
 * `import { Button, Input } from 'src/components';`
 *
 * ---
 *
 * The following component structure should be observed:
 *
 *   components/
 *   ├── index.tsx
 *   ├── Button/
 *   │ ├── index.tsx
 *   │ ├── ButtonGroup.tsx
 *   │ ├── Button.css
 *   │ ├── buttonUtils.ts
 *   │ ├── Button.test.tsx
 *   │ └── ButtonGroup.css
 *   ├── ComponentName/
 *   │ ├── index.tsx
 *   │ ├── RelatedComponent.tsx
 *   │ ├── ComponentName.css
 *   │ ├── ComponentNames.test.tsx
 *   │ ├── anyOtherRelatedFile.ts
 *   │ └── RelatedComponent.css
 *   └── ...
 *
 * ---
 *
 * Alphabetical order plz
 *
 */

export { AdManager } from './AdManager';
export { default as Box } from './Box';
export { Card } from './Card';
export { JumboRadioButtons } from './JumboRadioButtons';
export { Grid, Col } from './Grid';
export { GrowthLevelIndicatorClassic } from './GrowthLevelIndicatorClassic';
export { default as LoadingSkeleton } from './LoadingSkeleton';
export { ModeSelector } from './ModeSelector';
export { PointsPill } from './PointsPill';
export { default as ClassicButton } from './ClassicButton';
export { ButtonColor } from './ClassicButton/constants';
export { default as ClassicLinkButton } from './ClassicButton/ClassicLinkButton';
export { default as StaticTable } from './StaticTable';
export { Toggle } from './Toggle';
export { default as VisibilityChecker } from './VisibilityChecker';

import styled, { css } from 'styled-components';

export const Table = styled.table(
  ({ theme }) => css`
    border-collapse: collapse;
    color: ${theme.co.neutral99};
    font-weight: ${theme.DEPRECATED_ty.mediumWeight};
    text-align: left;
    width: 100%;

    th,
    td {
      padding: ${theme.sz.s3} 0;
      width: 50%;
    }

    td {
      border-top: 1px solid ${theme.co.secondary60};
      font-size: ${theme.DEPRECATED_ty.medium};
    }
  `,
);

export const Head = styled.thead(
  ({ theme }) => css`
    font-family: ${theme.DEPRECATED_ty.boing};
    font-size: ${theme.DEPRECATED_ty.large};
    font-weight: ${theme.DEPRECATED_ty.semiBold};
  `,
);

import React, { FC, useEffect, useRef } from 'react';

interface Props {
  onBottomHit: () => void;
  isLoading?: boolean;
  hasMoreData?: boolean;
}

const VisibilityChecker: FC<Props> = ({ onBottomHit, isLoading, hasMoreData }) => {
  const contentRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const onScroll = () => {
      const element = contentRef.current?.getBoundingClientRect();

      if (!element) return;

      const isVisible = element.bottom <= window.innerHeight;

      if (!isLoading && hasMoreData && isVisible) {
        onBottomHit();
      }
    };

    onScroll(); // initial check

    document.addEventListener('scroll', onScroll);

    return () => document.removeEventListener('scroll', onScroll);
  }, [onBottomHit, isLoading, hasMoreData]);

  return <div ref={contentRef} />;
};

export default VisibilityChecker;

import styled from 'styled-components';
import { Icon as Icn } from 'src/components';
import theme from 'src/utils/theme';

type FillColorType = {
  $isSelected: boolean;
  $selectedColor: string;
};

export const getFillColor = ({ $isSelected, $selectedColor }: FillColorType) => {
  if ($isSelected) return $selectedColor;

  return theme.co.secondary80;
};

export const Button = styled.button.attrs({
  type: 'button',
})`
  border: none;
  background: none;
  cursor: pointer;
  padding: 0;
  transition: 0.2s transform ease-in-out;

  &:hover {
    transform: scale(1.1);
  }

  &:active {
    transform: scale(0.9);
  }

  &:disabled {
    cursor: not-allowed;
  }
`;

export const Icon = styled(Icn)<FillColorType>`
  display: flex;
  width: max-content;

  svg {
    height: ${theme.sz.s6};
    width: ${theme.sz.s6};
  }

  path {
    transition: 0.2s fill ease-in-out;
    fill: ${({ $isSelected, $selectedColor }) => getFillColor({ $isSelected, $selectedColor })};
  }
`;

export const NETWORK_CODE = '22877234096';
export const AD_UNIT_CODE = 'ca-pub-9180263805807836-tag';
export const AD_REWARDED_UNIT_CODE = 'cc-pub-9180263805807836-tag';
export const AD_REWARDED_SLOT_CODE = 'div-gpt-ad-1680617072751-0';
export const AD_UNIT_PATH_LOCALHOST = '/22639388115/rewarded_web_example';

export const SIZE = {
  skyscraper: [
    [120, 600],
    [160, 600],
    [300, 600],
    [300, 1050],
  ],
  banner: [
    [120, 20],
    [168, 42],
    [216, 54],
    [300, 50],
    [468, 60],
    [728, 90],
  ],
  squareRectangle: [
    [300, 250],
    [336, 280],
    [580, 400],
  ],
};

export const SIZE_MAPPING = {
  skyscraper: [
    [[1920, 1080], [[300, 1050]]],
    [
      [1280, 720],
      [
        [300, 600],
        [160, 600],
      ],
    ],
    [[800, 600], [[120, 600]]],
  ] as googletag.SizeMappingArray,
  banner: [
    [[960, 1080], [[728, 90]]],
    [[800, 600], [[468, 60]]],
    [[540, 720], [[468, 60]]],
    // TO-DO: figure out why google still serves wider ads on small devices
    // https://memrise.atlassian.net/browse/WEBSITE-5571
    // [[420, 600], [[300, 50]]],
  ] as googletag.SizeMappingArray,
  squareRectangle: [
    [[1920, 1080], [[580, 400]]],
    [[1280, 720], [[336, 280]]],
    [[800, 600], [[300, 250]]],
  ] as googletag.SizeMappingArray,
};

export type AdSize = 'skyscraper' | 'banner' | 'squareRectangle';
export type BusinessModelType = 'immerse' | 'communicate';

export const defaultAnimationOptions = {
  loop: false,
  autoplay: true,
  rendererSettings: {
    preserveAspectRatio: 'xMidYMid slice',
  },
  style: {
    width: '100%',
    height: '100%',
    overflow: 'hidden',
    margin: '0px auto',
    outline: 'none',
  },
};

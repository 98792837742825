import styled, { DefaultTheme, css } from 'styled-components';
import HeaderTexture from 'images/card-header-texture.png';
import { transparentize } from 'polished';

export const cardColorList = [
  'blue',
  'darkBlue',
  'green',
  'orange',
  'purple',
  'white',
  'yellow',
] as const;

export type CardColor = (typeof cardColorList)[number];

const colors = (
  theme: DefaultTheme,
): Record<
  CardColor,
  {
    headerBg: string;
    headerColor: string;
    contentBg: string;
    contentColor: string;
  }
> => ({
  orange: {
    headerBg: theme.co.warning50,
    headerColor: theme.co.neutral20,
    contentBg: theme.co.neutral99,
    contentColor: theme.co.neutral20,
  },
  purple: {
    headerBg: theme.co.tertiary50,
    headerColor: theme.co.tertiary0,
    contentBg: theme.co.tertiary70,
    contentColor: theme.co.tertiary0,
  },
  blue: {
    headerBg: theme.co.information50,
    headerColor: theme.co.primary99,
    contentBg: theme.co.information80,
    contentColor: theme.co.neutral20,
  },
  darkBlue: {
    headerBg: theme.co.information10,
    headerColor: theme.co.primary99,
    contentBg: theme.co.information10,
    contentColor: theme.co.primary99,
  },
  green: {
    headerBg: theme.co.success30,
    headerColor: theme.co.primary99,
    contentBg: theme.co.success95,
    contentColor: theme.co.neutral20,
  },
  white: {
    headerBg: theme.co.primary99,
    headerColor: theme.co.neutral20,
    contentBg: theme.co.neutral99,
    contentColor: theme.co.neutral20,
  },
  yellow: {
    headerBg: theme.co.primary20,
    headerColor: theme.co.secondary20,
    contentBg: transparentize(0.8, theme.co.primary20),
    contentColor: theme.co.secondary20,
  },
});

export const CardContainer = styled.div<{
  $cardColor: CardColor;
}>(
  ({ theme, $cardColor }) => css`
    background-color: ${colors(theme)[$cardColor].contentBg};
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: ${theme.bo.large};
    overflow: hidden;
    position: relative;
  `,
);

export const Title = styled.h4<{ $cardColor: CardColor }>(
  ({ theme, $cardColor }) => css`
    color: ${colors(theme)[$cardColor].headerColor};
    font-size: ${theme.DEPRECATED_ty.large};
    font-weight: ${theme.DEPRECATED_ty.bold};
    margin: 0;
    position: relative;
    top: -0.1rem; /* optically nudge up for weight of bg texture */
    padding: ${theme.sz.s6} ${theme.sz.s8};
  `,
);

export const InnerTitle = styled.h4(
  ({ theme }) => css`
    font-size: ${theme.DEPRECATED_ty.large};
    font-weight: ${theme.DEPRECATED_ty.bold};
    margin: 0;
    margin-bottom: ${theme.sz.s2};
  `,
);

export const Header = styled.header<{ $cardColor: CardColor }>(
  ({ theme, $cardColor }) => css`
    background-color: ${colors(theme)[$cardColor].headerBg};
    border-radius: ${theme.bo.large} ${theme.bo.large} 0 0;
    mask-image: url('${HeaderTexture}');
    mask-repeat: no-repeat;
    mask-size: 100% 100%;
  `,
);

export const Content = styled.div<{
  $withPadding: boolean;
  $cardColor: CardColor;
}>(
  ({ theme, $withPadding, $cardColor }) => css`
    color: ${colors(theme)[$cardColor].contentColor};
    padding: ${$withPadding && theme.sz.s8};
  `,
);

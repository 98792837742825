import React, { ChangeEvent, FC } from 'react';
import { Stack } from 'src/components';
import { AnimatePresence } from 'framer-motion';
import * as S from './styles';

type JumboRadioButtonsOption = {
  /**
   * String represented the data to be selected for this option.
   * Not rendered to the user.
   * Must be unique.
   */
  value: string;

  /** Big label to render on the left of the button */
  mainLabel: string;

  /** Small label to render on the right of the button */
  sideLabel: string;
};

export type JumboRadioButtonsProps = {
  /**
   * The currently selected value for the component.
   * Must be one of the `value` properties on the options elements
   */
  selectedValue: string;

  /** Callback that fires when a radio is selected */
  onSelect: (value: string) => void;

  /** Array of option data to render */
  options: JumboRadioButtonsOption[];

  /**
   * As this component is implemented as a radio group, we need to name the
   * group with a unique string.
   *
   * This defaults to "radioBars" but should be overriden here if there are
   * other radio groups on the page.
   *
   * @default 'radioBars'
   * @see https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/radio#defining_a_radio_group
   */
  name?: string;
};

/**
 * This component should appear inside a <form />
 */
export const JumboRadioButtons: FC<JumboRadioButtonsProps> = ({
  name = 'radioBars',
  options,
  selectedValue,
  onSelect,
}) => (
  <Stack $direction="vertical">
    {options.map(({ value, mainLabel, sideLabel }) => {
      const checked = selectedValue === value;
      return (
        <S.LabelButton key={value} $checked={checked}>
          <AnimatePresence>{checked && <S.Tick />}</AnimatePresence>
          <S.MainLabel>{mainLabel}</S.MainLabel>
          <S.SideLabel $checked={checked}>{sideLabel}</S.SideLabel>
          <S.RadioInput
            type="radio"
            name={name}
            value={value}
            checked={checked}
            onChange={(e: ChangeEvent<HTMLInputElement>) => onSelect(e.target.value)}
          />
        </S.LabelButton>
      );
    })}
  </Stack>
);

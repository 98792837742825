import React from 'react';
import styled, { css } from 'styled-components';
import { motion } from 'framer-motion';
import IconComponent from '../../components/Icon';

export const Wrapper = styled.div`
  @media (min-width: ${({ theme }) => theme.sz.md}) {
    width: auto;
  }
`;

const animateProps = {
  initial: {
    opacity: 1.0,
    scale: 0.2,
  },
  animate: {
    opacity: 1.0,
    scale: 1.0,
  },
  exit: {
    opacity: 0,
  },
};

export const AlreadyKnown = () => (
  <svg
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    data-testid="alreadyKnown"
  >
    <path
      d="M15.1277 20.8508C13.8411 21.4086 12.4652 21.6875 11 21.6875C9.53484 21.6875 8.15892 21.4086 6.87226 20.8508C5.5856 20.293 4.4514 19.5232 3.46966 18.5415C2.48793 17.5598 1.71444 16.4256 1.1492 15.1389C0.591401 13.8448 0.3125 12.4652 0.3125 11C0.3125 9.54228 0.591401 8.17008 1.1492 6.88342C1.70701 5.58931 2.47677 4.4514 3.45851 3.46966C4.44768 2.48049 5.5856 1.70701 6.87226 1.1492C8.15892 0.591401 9.53112 0.3125 10.9888 0.3125C12.454 0.3125 13.8299 0.591401 15.1166 1.1492C16.4032 1.70701 17.5374 2.48049 18.5192 3.46966C19.5084 4.4514 20.2818 5.58931 20.8396 6.88342C21.4049 8.17008 21.6875 9.54228 21.6875 11C21.6875 12.4652 21.4086 13.8448 20.8508 15.1389C20.293 16.4256 19.5195 17.5598 18.5303 18.5415C17.5486 19.5232 16.4144 20.293 15.1277 20.8508ZM9.95715 15.0219L17.2909 7.84405C17.6246 7.51745 17.6246 6.98035 17.2909 6.65375C16.9673 6.33702 16.4499 6.3369 16.1261 6.65347L9.79004 12.8491C9.37491 13.255 8.71165 13.2552 8.29622 12.8496L6.66565 11.2576C6.34173 10.9414 5.82454 10.9417 5.50101 11.2583C5.16721 11.585 5.16721 12.1223 5.50101 12.449L8.12978 15.0219C8.63754 15.5189 9.44939 15.5189 9.95715 15.0219Z"
      fill="#74D8C1"
    />
  </svg>
);

export const GL0 = () => (
  <motion.svg
    data-testid="gl0"
    width="100%"
    viewBox="0 0 225 235"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...animateProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
      fill="#B1D8E5"
    />
    <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="15" width="225" height="220">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.904 256.6C174.045 257.969 233.808 179.8 233.808 179.8C233.808 179.8 174.134 148.371 116.904 147C59.6736 145.629 0 174.2 0 174.2C0 174.2 59.7627 255.231 116.904 256.6Z"
        fill="#55AA87"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M103.91 55.8C87.871 59.8733 32.836 94.5906 28.3034 101.278C28.3034 105.023 33.8228 110.333 41.5067 106.901C49.1907 103.47 82.0616 83.4385 82.0616 83.4385C82.0616 83.4385 82.72 85.5049 77.5447 89.1705C72.3694 92.8362 26.356 120.277 21.1494 124.69C18.3662 129.103 32.8516 133.827 41.8837 129.258C50.9158 124.69 92.0301 98.1242 92.0301 98.1242C92.0301 98.1242 92.9265 102.401 85.2356 106.786C77.5447 111.171 31.2638 140.914 27.3914 143.543C23.519 146.172 40.2038 153.895 50.0107 147.834C59.8176 141.774 77.7375 130.592 77.7375 130.592C77.7375 130.592 77.2928 142.071 81.2642 146.362C85.2356 150.653 99.8426 172.048 101.837 174.161C103.831 176.273 114.716 162.38 110.211 153.814C105.705 145.248 97.6814 138.947 97.6814 127.393C97.6814 115.84 108.446 110.296 116.39 110.296C124.335 110.296 131.159 117.674 131.159 125.958C131.159 134.241 123.59 150.794 121.727 155.242C119.864 159.689 119.95 169.053 125.554 173.657C131.159 178.262 133.811 169.832 136.464 166.263C139.117 162.695 160.833 127.549 163.041 115.834C165.249 104.119 164.573 96.6025 158.639 85.4058C152.704 74.2092 136.575 60.7035 123.393 55.8"
      fill="#2B3648"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M104.116 56.0415C104.116 56.0415 111.828 51.8668 123.794 56.0887C135.761 60.3106 113.675 60.6 113.675 60.6L104.116 56.0415Z"
      fill="#2B3648"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.321 182.914C107.646 187.813 108.602 191.657 112.498 195.121C116.395 198.584 120.94 187.888 120.94 183.739C120.94 179.59 118.735 170.2 115.616 170.2C112.498 170.2 108.995 178.014 108.321 182.914Z"
      fill="#2B3648"
    />
  </motion.svg>
);

export const GL1 = () => (
  <motion.svg
    data-testid="gl1"
    width="100%"
    viewBox="0 0 225 235"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...animateProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
      fill="#B1D8E5"
    />
    <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="15" width="225" height="220">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.904 256.6C174.045 257.969 233.808 179.8 233.808 179.8C233.808 179.8 174.134 148.371 116.904 147C59.6736 145.629 0 174.2 0 174.2C0 174.2 59.7627 255.231 116.904 256.6Z"
        fill="#55AA87"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.202 182.914C107.518 187.813 108.487 191.657 112.439 195.121C116.39 198.584 121 187.888 121 183.739C121 179.59 118.764 170.2 115.601 170.2C112.439 170.2 108.885 178.014 108.202 182.914Z"
      fill="#2B3648"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.47 170.053C116.485 172.743 112.733 172.884 111.55 170.275L107.091 160.442C97.7942 139.943 100.064 116.053 113.054 97.6714C114.919 95.0312 119.02 95.7749 119.839 98.9023L123.362 112.345C127.499 128.126 126.711 144.794 121.105 160.115L117.47 170.053Z"
      fill="#174037"
    />
  </motion.svg>
);

export const GL2 = () => (
  <motion.svg
    data-testid="gl2"
    width="100%"
    viewBox="0 0 225 235"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...animateProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
      fill="#B1D8E5"
    />
    <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="15" width="225" height="220">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.904 256.6C174.045 257.969 233.808 179.8 233.808 179.8C233.808 179.8 174.134 148.371 116.904 147C59.6736 145.629 0 174.2 0 174.2C0 174.2 59.7627 255.231 116.904 256.6Z"
        fill="#55AA87"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.202 182.914C107.518 187.813 108.487 191.657 112.439 195.121C116.39 198.584 121 187.888 121 183.739C121 179.59 118.764 170.2 115.601 170.2C112.439 170.2 108.885 178.014 108.202 182.914Z"
      fill="#2B3648"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.541 168.556C117.688 171.417 114.289 173.014 112.18 171.075L104.213 163.749C87.6486 148.518 80.401 125.648 85.1696 103.657C85.8546 100.498 89.9188 99.5796 91.8956 102.137L100.405 113.148C110.379 126.054 116.17 141.7 117.002 157.99L117.541 168.556Z"
      fill="#174037"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.922 168.546C111.776 171.407 115.175 173.004 117.284 171.064L125.251 163.739C141.815 148.508 149.063 125.638 144.294 103.647C143.609 100.488 139.545 99.5693 137.568 102.127L129.059 113.138C119.084 126.043 113.293 141.69 112.462 157.979L111.922 168.546Z"
      fill="#174037"
    />
  </motion.svg>
);

export const GL3 = () => (
  <motion.svg
    data-testid="gl3"
    width="100%"
    viewBox="0 0 225 235"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...animateProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
      fill="#B1D8E5"
    />
    <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="15" width="225" height="220">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.904 256.6C174.045 257.969 233.808 179.8 233.808 179.8C233.808 179.8 174.134 148.371 116.904 147C59.6736 145.629 0 174.2 0 174.2C0 174.2 59.7627 255.231 116.904 256.6Z"
        fill="#55AA87"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.202 182.914C107.518 187.813 108.487 191.657 112.439 195.121C116.39 198.584 121 187.888 121 183.739C121 179.59 118.764 170.2 115.601 170.2C112.439 170.2 108.885 178.014 108.202 182.914Z"
      fill="#2B3648"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.541 168.556C117.688 171.417 114.289 173.014 112.18 171.075L104.213 163.749C87.6486 148.518 80.401 125.648 85.1696 103.657C85.8546 100.498 89.9188 99.5796 91.8956 102.137L100.405 113.148C110.379 126.054 116.17 141.7 117.002 157.99L117.541 168.556Z"
      fill="#174037"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.922 168.546C111.776 171.407 115.175 173.004 117.284 171.064L125.251 163.739C141.815 148.508 149.063 125.638 144.294 103.647C143.609 100.488 139.545 99.5693 137.568 102.127L129.059 113.138C119.084 126.043 113.293 141.69 112.462 157.979L111.922 168.546Z"
      fill="#174037"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.974 99.8H123.38C123.38 99.8 117.56 118.941 116.974 128.6C116.388 138.259 120.177 152.6 120.177 152.6H113.771C113.771 152.6 110.019 135.25 110.568 126.2C111.117 117.15 116.974 99.8 116.974 99.8Z"
      fill="#174037"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.907 109.4C125.993 109.4 129.715 103.281 129.715 98.2C129.715 93.119 123.591 89.4 118.505 89.4C113.42 89.4 108.897 95.519 108.897 100.6C108.897 105.681 115.822 109.4 120.907 109.4Z"
      fill="#174037"
    />
  </motion.svg>
);

export const GL4 = () => (
  <motion.svg
    data-testid="gl4"
    width="100%"
    viewBox="0 0 225 235"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...animateProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
      fill="#B1D8E5"
    />
    <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="15" width="225" height="220">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.904 256.6C174.045 257.969 233.808 179.8 233.808 179.8C233.808 179.8 174.134 148.371 116.904 147C59.6736 145.629 0 174.2 0 174.2C0 174.2 59.7627 255.231 116.904 256.6Z"
        fill="#55AA87"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.202 182.914C107.518 187.813 108.487 191.657 112.439 195.121C116.39 198.584 121 187.888 121 183.739C121 179.59 118.764 170.2 115.601 170.2C112.439 170.2 108.885 178.014 108.202 182.914Z"
      fill="#2B3648"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M117.541 168.556C117.687 171.417 114.289 173.014 112.18 171.075L104.212 163.749C87.6485 148.518 80.401 125.648 85.1695 103.657C85.8546 100.498 89.9188 99.5796 91.8955 102.137L100.405 113.148C110.379 126.054 116.17 141.7 117.002 157.99L117.541 168.556Z"
      fill="#174037"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.922 168.546C111.776 171.407 115.175 173.004 117.284 171.064L125.251 163.739C141.815 148.508 149.063 125.638 144.294 103.647C143.609 100.488 139.545 99.5693 137.568 102.127L129.059 113.138C119.085 126.043 113.294 141.69 112.462 157.979L111.922 168.546Z"
      fill="#174037"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.373 77.4H121.778C121.778 77.4 115.959 108.722 115.373 124.527C114.787 140.332 118.575 163.8 118.575 163.8H112.17C112.17 163.8 108.418 135.409 108.967 120.6C109.516 105.791 115.373 77.4 115.373 77.4Z"
      fill="#174037"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.908 84.6C125.993 84.6 129.715 78.481 129.715 73.4C129.715 68.319 123.591 64.6 118.505 64.6C113.42 64.6 108.897 70.719 108.897 75.8C108.897 80.881 115.822 84.6 120.908 84.6Z"
      fill="#174037"
    />
  </motion.svg>
);

export const GL5 = () => (
  <motion.svg
    data-testid="gl5"
    width="100%"
    viewBox="0 0 225 235"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...animateProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
      fill="#B1D8E5"
    />
    <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="15" width="225" height="220">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.904 256.6C174.045 257.969 233.808 179.8 233.808 179.8C233.808 179.8 174.134 148.371 116.904 147C59.6736 145.629 0 174.2 0 174.2C0 174.2 59.7627 255.231 116.904 256.6Z"
        fill="#55AA87"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.202 182.914C107.518 187.813 108.487 191.657 112.439 195.121C116.39 198.584 121 187.888 121 183.739C121 179.59 118.764 170.2 115.601 170.2C112.439 170.2 108.885 178.014 108.202 182.914Z"
      fill="#2B3648"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.623 170.769C117.361 173.537 114.368 175.806 111.902 174.348L102.576 168.832C83.2098 157.378 71.3657 136.517 71.4539 114.018C71.4666 110.784 75.2518 109.04 77.7178 111.132L88.3406 120.141C100.777 130.688 109.693 144.783 113.895 160.539L116.623 170.769Z"
      fill="#174037"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.093 170.141C110.259 172.882 113.171 175.254 115.686 173.882L125.201 168.694C144.955 157.923 157.519 137.489 158.217 115C158.317 111.768 154.595 109.893 152.058 111.897L141.125 120.532C128.329 130.638 118.927 144.412 114.178 160.011L111.093 170.141Z"
      fill="#174037"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.812 89.1235C124.942 90.3207 137.722 87.9331 137.722 75.9491C137.722 63.965 132.141 55.8 120.689 55.8C109.236 55.8 100.89 58.5403 100.89 70.5243C100.89 82.5084 108.683 87.9262 116.812 89.1235Z"
      fill="#E48A92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.373 77.4H121.778C121.778 77.4 115.959 108.722 115.373 124.527C114.787 140.332 118.575 163.8 118.575 163.8H112.17C112.17 163.8 108.418 135.409 108.967 120.6C109.516 105.791 115.373 77.4 115.373 77.4Z"
      fill="#174037"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.908 84.6C125.993 84.6 129.715 78.481 129.715 73.4C129.715 68.319 123.591 64.6 118.505 64.6C113.42 64.6 108.897 70.719 108.897 75.8C108.897 80.881 115.822 84.6 120.908 84.6Z"
      fill="#174037"
    />
  </motion.svg>
);

export const GL6 = () => (
  <motion.svg
    data-testid="gl6"
    width="100%"
    viewBox="0 0 225 235"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...animateProps}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.9 234.202C191.37 234.202 223.876 178.295 224.473 113.778C225.022 54.5094 171.247 15.5792 114.902 15.3763C58.5572 15.1734 -0.0231437 51.3151 6.8595e-06 124.789C0.0216477 193.472 60.8541 234.202 112.9 234.202Z"
      fill="#B1D8E5"
    />
    <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="15" width="225" height="220">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.9 234.202C191.37 234.202 223.876 178.295 224.473 113.778C225.022 54.5094 171.247 15.5792 114.902 15.3763C58.5572 15.1734 -0.0231437 51.3151 6.8595e-06 124.789C0.0216477 193.472 60.8541 234.202 112.9 234.202Z"
        fill="white"
      />
    </mask>
    <g mask="url(#mask0)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.904 256.563C174.045 257.929 233.808 179.894 233.808 179.894C233.808 179.894 174.134 148.519 116.904 147.15C59.6736 145.782 0 174.304 0 174.304C0 174.304 59.7627 255.197 116.904 256.563Z"
        fill="#55AA87"
      />
    </g>
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M108.202 183.115C107.518 187.938 108.487 191.722 112.439 195.131C116.39 198.54 121 188.012 121 183.927C121 179.843 118.764 170.6 115.601 170.6C112.439 170.6 108.885 178.292 108.202 183.115Z"
      fill="#2B3648"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M116.621 170.87C117.36 173.637 114.369 175.908 111.902 174.451L102.548 168.928C83.2013 157.505 71.3657 136.678 71.4539 114.211C71.4666 110.986 75.2404 109.246 77.7015 111.329L88.313 120.313C100.767 130.857 109.698 144.957 113.91 160.722L116.621 170.87Z"
      fill="#174037"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M111.096 170.242C110.26 172.983 113.17 175.356 115.687 173.986L125.232 168.79C144.964 158.049 157.52 137.649 158.218 115.193C158.318 111.97 154.606 110.098 152.074 112.095L141.15 120.707C128.338 130.808 118.921 144.586 114.162 160.191L111.096 170.242Z"
      fill="#174037"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M109.982 119.809C140.378 124.078 188.167 115.565 188.167 72.8382C188.167 30.1111 167.299 1 124.476 1C81.6537 1 50.4448 10.7701 50.4448 53.4971C50.4448 96.2242 79.5861 115.541 109.982 119.809Z"
      fill="#E48A92"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M112.259 106.456C135.233 109.757 171.352 103.174 171.352 70.1316C171.352 37.0894 155.58 14.5768 123.214 14.5768C90.8479 14.5768 67.2598 22.1323 67.2598 55.1746C67.2598 88.2168 89.2851 103.155 112.259 106.456Z"
      stroke="#640928"
      strokeWidth="5.6"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M115.373 77.6689H121.778C121.778 77.6689 115.959 108.938 115.373 124.716C114.787 140.494 118.575 163.922 118.575 163.922H112.17C112.17 163.922 108.418 135.579 108.967 120.795C109.516 106.012 115.373 77.6689 115.373 77.6689Z"
      fill="#174037"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M120.908 84.8567C125.993 84.8567 129.715 78.7481 129.715 73.6758C129.715 68.6034 123.591 64.8908 118.505 64.8908C113.42 64.8908 108.897 70.9993 108.897 76.0717C108.897 81.144 115.822 84.8567 120.908 84.8567Z"
      fill="#174037"
    />
  </motion.svg>
);

export const Review = () => (
  <motion.svg
    data-testid="glreview"
    width="100%"
    viewBox="0 0 225 235"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...animateProps}
  >
    <g clipPath="url(#clip0)">
      <g opacity="0.945126">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
          fill="#B1D8E5"
        />
        <mask id="mask0" maskUnits="userSpaceOnUse" x="0" y="15" width="225" height="220">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0)">
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M116.904 256.6C174.045 257.969 233.808 179.8 233.808 179.8C233.808 179.8 174.134 148.371 116.904 147C59.6736 145.629 0 174.2 0 174.2C0 174.2 59.7627 255.231 116.904 256.6Z"
            fill="#55AA87"
          />
        </g>
      </g>
      <path
        opacity="0.945126"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M108.202 182.914C107.518 187.813 108.487 191.657 112.439 195.121C116.39 198.584 121 187.888 121 183.739C121 179.59 118.764 170.2 115.601 170.2C112.439 170.2 108.885 178.014 108.202 182.914Z"
        fill="#2B3648"
      />
      <path
        opacity="0.945126"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M116.623 170.769C117.361 173.537 114.368 175.806 111.902 174.348L102.576 168.832C83.2098 157.378 71.3657 136.517 71.4539 114.018C71.4666 110.784 75.2518 109.04 77.7178 111.132L88.3406 120.141C100.777 130.688 109.693 144.783 113.895 160.539L116.623 170.769Z"
        fill="#174037"
      />
      <path
        opacity="0.945126"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M111.093 170.141C110.259 172.882 113.171 175.254 115.686 173.882L125.201 168.694C144.955 157.923 157.519 137.489 158.217 115C158.317 111.768 154.595 109.893 152.058 111.897L141.125 120.532C128.329 130.638 118.927 144.412 114.178 160.011L111.093 170.141Z"
        fill="#174037"
      />
      <path
        opacity="0.945126"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M113.235 111.155C133.027 113.948 164.146 108.377 164.146 80.4145C164.146 52.4518 150.557 33.4 122.673 33.4C94.7882 33.4 74.4662 39.794 74.4662 67.7568C74.4662 95.7195 93.4419 108.361 113.235 111.155Z"
        fill="#E48A92"
      />
      <path
        opacity="0.945126"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M114.692 102.5C129.734 104.666 153.384 100.346 153.384 78.6602C153.384 56.9748 143.057 42.2 121.865 42.2C100.673 42.2 85.2278 47.1586 85.2278 68.844C85.2278 90.5294 99.6493 100.333 114.692 102.5Z"
        stroke="#640928"
        strokeWidth="5.6"
      />
      <path
        opacity="0.945126"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M115.373 77.4H121.778C121.778 77.4 115.959 108.722 115.373 124.527C114.787 140.332 118.575 163.8 118.575 163.8H112.17C112.17 163.8 108.418 135.409 108.967 120.6C109.516 105.791 115.373 77.4 115.373 77.4Z"
        fill="#174037"
      />
      <path
        opacity="0.945126"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M120.908 84.6C125.993 84.6 129.715 78.481 129.715 73.4C129.715 68.319 123.591 64.6 118.505 64.6C113.42 64.6 108.897 70.719 108.897 75.8C108.897 80.881 115.822 84.6 120.908 84.6Z"
        fill="#174037"
      />
      <path
        opacity="0.5"
        fillRule="evenodd"
        clipRule="evenodd"
        d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
        fill="#FCFAF2"
      />
      <mask id="mask1" maskUnits="userSpaceOnUse" x="0" y="15" width="225" height="220">
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M112.9 234.201C191.37 234.201 223.876 178.198 224.473 113.571C225.022 54.2009 171.247 15.2041 114.902 15.0009C58.5572 14.7976 -0.0231437 51.0011 6.8595e-06 124.601C0.0216477 193.401 60.8541 234.201 112.9 234.201Z"
          fill="white"
        />
      </mask>
      <g mask="url(#mask1)"></g>
    </g>
    <defs>
      <clipPath id="clip0">
        <rect y="15" width="225" height="220" fill="white" />
      </clipPath>
    </defs>
  </motion.svg>
);

export const Icon = styled(IconComponent)(
  ({ theme }) => css`
    position: absolute;
    bottom: ${theme.sz.s2};
    left: calc(${theme.sz.s12} - ${theme.sz.s3});
    border-radius: 50%;

    svg {
      width: ${theme.sz.s6};
      height: ${theme.sz.s6};
    }
  `,
);

export const IconContainer = styled.div(() => css``);

export const MainIcon = styled.div(
  ({ theme }) => css`
    svg {
      width: ${theme.sz.s12};
      height: ${theme.sz.s12};
    }
  `,
);

import React, { FC } from 'react';
import { defineMessages, useIntl } from 'react-intl';
import { IconName } from '../../components';
import * as S from './styles';

const msgs = defineMessages({
  toggleIcon: {
    id: 'toggle_icon',
    defaultMessage: 'Toggle {iconName} button',
  },
  difficultWords: {
    id: 'difficult_words',
    defaultMessage: 'difficult words',
  },
  ignoreWords: {
    id: 'ignore_word',
    defaultMessage: 'ignore word',
  },
});

type ToggleProps = {
  handleClick: () => void;
  handleKeyUp?: () => void;
  iconName: IconName;
  isSelected: boolean;
  selectedColor: string;
  disabled?: boolean;
};

export const Toggle: FC<ToggleProps> = ({
  handleClick,
  handleKeyUp,
  iconName,
  isSelected,
  selectedColor,
  disabled,
}) => {
  const intl = useIntl();
  const toggleIconText = intl.formatMessage(msgs.toggleIcon, {
    iconName:
      iconName === 'difficultWords'
        ? intl.formatMessage(msgs.difficultWords)
        : intl.formatMessage(msgs.ignoreWords),
  });

  return (
    <S.Button
      onClick={handleClick}
      onKeyUp={handleKeyUp}
      aria-label={toggleIconText}
      disabled={disabled}
      data-testid={`${iconName}-${isSelected ? 'selected' : 'unselected'}`}
    >
      <S.Icon name={iconName} $isSelected={isSelected} $selectedColor={selectedColor} />
    </S.Button>
  );
};

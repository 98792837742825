import styled, { css, keyframes } from 'styled-components';
import theme from 'src/utils/theme';
import { Pill as DefaultPill } from 'src/components';

const innerCircleAnimation = keyframes`
  0% {
    transform: scale(0);
  }
  60% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
`;

export const PointsCelebrationInner = styled.div`
  animation: ${innerCircleAnimation} 0.4s linear;
  background: ${theme.co.neutral98};
  border-radius: 100px;
  height: 200px;
  transform: scale(0);
  width: 200px;
`;

const outerCircleAnimation = keyframes`
  20% {
    transform: scale(0);
  }
  50% {
    transform: scale(1);
  }
  100% {
    transform: scale(1);
  }
`;

export const PointsCelebration = styled.div`
  animation: ${outerCircleAnimation} 0.4s linear;
  width: 200px;
  height: 200px;
  background: ${theme.co.warning50};
  border-radius: 100px;
  position: absolute;
  left: -40px;
  top: -75px;
  z-index: 1;
  transform: scale(0);
`;

const textAnimation = keyframes`
    0% {
      opacity: 1;
      transform: translate(0,0);
    }

    40% {
      opacity: 1;
      transform: translate(0,0);
    }

    100% {
      opacity: 0;
      transform: translate(0,-3em);
    }
`;

const pillAnimation = keyframes`
  0% {
    transform: scale(1);
  }
  15% {
    transform: scale(0.4);
  }
  16% {
    transform: scale(1);
    background: ${theme.co.primary20};
  }
  60% {
    background: ${theme.co.primary20};
  }
  100% {
    background: ${theme.co.neutral99};
  }
`;

export const Pill = styled(DefaultPill)<{ $animate: boolean }>`
  animation: ${({ $animate }) =>
    $animate &&
    css`
      ${pillAnimation} 1s ease-out
    `};
  background-color: ${theme.co.neutral98};
  position: relative;
  z-index: 2;
`;

export const CelebrationText = styled.span`
  animation: ${textAnimation} 2s ease-out;
  color: ${theme.co.error60};
  display: block;
  font-family: Open Sans;
  font-size: ${theme.sz.s6};
  font-weight: ${theme.DEPRECATED_ty.bold};
  opacity: 0;
  position: relative;
  z-index: 3;
`;

export const Container = styled.div`
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export const PointsContainer = styled.div`
  position: relative;
  width: 100%;
`;

import React, { FC, ReactNode } from 'react';
import * as S from './styles';

interface CardProps {
  title?: string;
  titlePlacement?: 'header' | 'content';
  color: S.CardColor;
  withPadding?: boolean;
  children: ReactNode;
}

export const Card: FC<CardProps> = ({
  title,
  titlePlacement = 'header',
  color,
  withPadding = true,
  children,
}) => (
  <S.CardContainer $cardColor={color}>
    {title && titlePlacement === 'header' && (
      <S.Header $cardColor={color}>
        <S.Title $cardColor={color} data-testid="card-title">
          {title}
        </S.Title>
      </S.Header>
    )}
    <S.Content $withPadding={withPadding} $cardColor={color}>
      {title && titlePlacement === 'content' && (
        <S.InnerTitle data-testid="card-inner-title">{title}</S.InnerTitle>
      )}
      <div>{children}</div>
    </S.Content>
  </S.CardContainer>
);

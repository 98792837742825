import React, { FC } from 'react';
import Link, { LinkProps } from 'next/link';
import ClassicAnchorButton, { ClassicAnchorButtonProps } from './ClassicAnchorButton';

interface LinkButtonProps extends Omit<ClassicAnchorButtonProps, 'as' | 'href' | 'prefetch'> {
  as?: LinkProps['as'];
  href?: LinkProps['href'];
  prefetch?: false | undefined;
}

/**
 * A wrapper component that gives you Next.js-compatible
 * internal links with Memrise button styles
 *
 * https://nextjs.org/docs#dynamic-routes
 *
 * Ref passed is to avoid issue listed here:
 * https://github.com/zeit/next.js/issues/7915
 */
const ClassicLinkButton: FC<LinkButtonProps> = ({ as, href, prefetch, ...anchorButtonProps }) => {
  const anchorButton = <ClassicAnchorButton {...anchorButtonProps} ref={null} />;

  if (href) {
    return (
      <Link as={as} href={href} passHref prefetch={prefetch} legacyBehavior>
        {anchorButton}
      </Link>
    );
  }

  return anchorButton;
};

export default ClassicLinkButton;

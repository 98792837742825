import React, { FC, useEffect, useRef } from 'react';
import { useScript } from 'src/hooks';
import { AD_UNIT_CODE, AdSize, NETWORK_CODE, SIZE, SIZE_MAPPING } from 'src/components/constants';
import * as S from './styles';

interface Props {
  size: AdSize;
}

export const AdManager: FC<Props> = ({ size }) => {
  const ref = useRef<HTMLModElement>(null);
  const [scriptHasLoaded, hasScriptLoadError] = useScript(
    'https://securepubads.g.doubleclick.net/tag/js/gpt.js',
    false,
    'head',
  );

  window.googletag = window.googletag || { cmd: [] };

  useEffect(() => {
    /* istanbul ignore else: empty else branch */
    if (!window.googletag || !googletag.apiReady) return;
    /* istanbul ignore else: empty else branch */
    if (scriptHasLoaded && window.googletag && googletag.apiReady) {
      /* istanbul ignore next: difficult to mock */
      googletag?.cmd?.push(async () => {
        try {
          // We must destroy all previous slots before creating any new ones
          googletag.destroySlots();

          // Define an ad slot for div with id ${size}.
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          const responsiveAdSlot = googletag
            .defineSlot(
              // Ad unit path follows the format /network-code/[parent-ad-unit-code/.../]ad-unit-code
              `/${NETWORK_CODE}/${AD_UNIT_CODE}`,
              SIZE[size] as googletag.GeneralSize,
              size,
            )!
            // Minimizes malicious creatives serving
            .setSafeFrameConfig({ sandbox: true })
            .addService(googletag.pubads());

          const mapping = googletag
            .sizeMapping()
            .addSize(SIZE_MAPPING[size][0][0], SIZE_MAPPING[size][0][1])
            .addSize(SIZE_MAPPING[size][1][0], SIZE_MAPPING[size][1][1])
            .addSize(SIZE_MAPPING[size][2][0], SIZE_MAPPING[size][2][1])
            // When viewport < the last size given, no ads may serve.
            .addSize([0, 0], [])
            .build();

          if (!mapping) return;
          responsiveAdSlot.defineSizeMapping(mapping);

          // Enable SRA and PubAdsService.
          googletag.pubads().enableSingleRequest();
          // Configure all ad slots on the page to be expanded by default, but
          // collapse slots that are unable to be filled with an ad.
          googletag.pubads().collapseEmptyDivs();
          googletag.enableServices();

          // Request and render an ad for the ${size} slot.
          googletag.display(size);
        } catch (e) {
          // Continue
        }
      });
    }
    // We need window.googletag and googletag.apiReady for the ad to render on each refresh
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scriptHasLoaded, window.googletag, googletag.apiReady]);

  return (
    <S.AdContainer data-testid="Ad-manager" $vertical={size === 'skyscraper'}>
      {!hasScriptLoadError && <S.Ad id={size} ref={ref} />}
    </S.AdContainer>
  );
};
